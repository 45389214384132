import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Recipe, ShopifyProduct } from '../entities/recipe';
import { Capsule } from '../../capsule/capsule';
import { RecipeWrapper } from '../entities/RecipeWrapper';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) {}

  getDefaultRecipes(token,from,size) {

    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.get<RecipeWrapper[]>( this.apiUrl+'/user/recipe/default?from='+from+'&pageSize='+size+'&fields=name', {headers} );
    
  }

  getMachineDefaultRecipes(token, revisionId: number) {

    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.get<RecipeWrapper[]>( this.apiUrl+'/machine/recipe/default?revision_id=' + revisionId, {headers} );
    
  }

  setMachineDefaultRecipeOrder(token, id: number, recipeOrder: number, revisionId: number) {
    const  headers = new  HttpHeaders().set("authorization", "Bearer " + token);
    const body = {
      order: recipeOrder,
      revision_id: revisionId,
    }

    return this.http.patch( this.apiUrl+'/machine/recipe/default/'+id, body, {headers} );
  }

  getDefaultRecipeCount(token) {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.get( this.apiUrl+'/user/recipe/default/count', {headers} );
  }

  getAllUserRecipeCount(token) {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.get<Number>( this.apiUrl+'/user/recipe/all/count', {headers} );
  }

  addCoffeeRecipe(token, recipe:Recipe): Observable<Recipe> {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.post<Recipe>( this.apiUrl+'/roaster/recipe/coffee', recipe, {headers} );
  }

  updateCoffeeRecipe(token, recipe:Recipe): Observable<Recipe> {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.put<Recipe>( this.apiUrl+'/user/recipe/coffee/'+recipe.id, recipe, {headers} );
  }

  addDripBagRecipe(token, recipe:Recipe): Observable<Recipe> {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.post<Recipe>( this.apiUrl+'/user/recipe/drip-bag', recipe, {headers} );
  }

  updateDripBagRecipe(token, recipe:Recipe): Observable<Recipe> {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.put<Recipe>( this.apiUrl+'/user/recipe/drip-bag/'+recipe.id, recipe, {headers} );
  }

  addTeaRecipe(token, recipe:Recipe): Observable<Recipe> {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.post<Recipe>( this.apiUrl+'/user/recipe/tea', recipe, {headers} );
  }

  updateTeaRecipe(token, recipe:Recipe): Observable<Recipe> {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.put<Recipe>( this.apiUrl+'/user/recipe/tea/'+recipe.id, recipe, {headers} );
  }

  deleteDefaultRecipe(token, recipeId:number) {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.delete( this.apiUrl+'/roaster/recipe/'+recipeId, {headers} );
  }

  updateRecipe(token, recipe:Recipe, isCustom:boolean) : Observable<Recipe> {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.put<Recipe>( this.apiUrl+'/user/recipe/coffee/'+recipe.id, recipe, {headers} );
  }

  getCapsules(token) {

    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.get<Capsule[]>( this.apiUrl+'/capsule?fields=name&pageSize=10&from=0&active=true', {headers} );
    
  }

  searchCapsule(token, roasterId: number, searchString: string, pageSize: number, from: number ) {

    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.get<Capsule[]>( this.apiUrl+'/capsule?active=true&roasterId='+roasterId+'&search='+searchString+'&fields=name&pageSize='+pageSize+'&from='+ from, {headers} );
    
  }

  getRoasterRecipes(token,id,from,size) {

    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.get<RecipeWrapper[]>( this.apiUrl+'/admin/roaster/'+id+'/recipe?from='+from+'&pageSize='+size+'&fields=name', {headers} );
    
  }

  searchRoasterRecipes(token,id,from,size,search: String) {

    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.get<RecipeWrapper[]>( this.apiUrl+'/admin/roaster/'+id+'/recipe?from='+from+'&pageSize='+size+'&fields=name&search='+search, {headers} );
    
  }

  deleteRoasterRecipe(token, recipeId:number) {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.delete( this.apiUrl+'/roaster/recipe/'+recipeId, {headers} );
  }

  updateRoasterCoffeeRecipe(token, recipe:Recipe): Observable<Recipe> {
    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);

    return this.http.put<Recipe>( this.apiUrl+'/roaster/recipe/coffee/'+recipe.id, recipe, {headers} );
  }

  updateRoasterRecipeOrder(token, id: number, recipeOrder: number) {
    const  headers = new  HttpHeaders().set("authorization", "Bearer " + token);
    const body = {
      recipeOrder: recipeOrder
    }

    return this.http.patch( this.apiUrl+'/roaster/recipe/'+id, body, {headers} );
  }

  getRecipeAvailabilityList(token, recipeId) {
    const  headers = new  HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<ShopifyProduct[]>( this.apiUrl + `/admin/recipe/${recipeId}/availabilities`, {headers} );
  }

  updateRecipeAvailabilityList(token, recipeId, warehouseList: ShopifyProduct[]) {
    const  headers = new  HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put<ShopifyProduct[]>( this.apiUrl + `/admin/recipe/${recipeId}/availabilities`, warehouseList, {headers} );
  }

}
