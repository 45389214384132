import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecipeService } from '../service/recipe.service';
import { Auth } from 'aws-amplify';
import { RecipeWrapper } from '../entities/RecipeWrapper';
import { DomSanitizer } from '@angular/platform-browser';
import { RoasterService } from 'src/app/roaster/service/roaster.service';
import { Roaster } from 'src/app/roaster/Roaster';
import { Revision } from '../../revision/entities/revision';
import { RevisionService } from '../../revision/service/revision.service';

@Component({
  selector: 'app-roaster-recipes',
  templateUrl: './roaster-recipes.component.html',
  styleUrls: ['./roaster-recipes.component.css']
})
export class RoasterRecipesComponent implements OnInit {

  accessToken: Object;
  recipes: RecipeWrapper[];
  allRecipes: Array<RecipeWrapper>;

  revisions: Revision[];

  currentPageNumber: number;
  pageSize: number;
  disableNext: boolean;

  pageStartIndex: number;
  pageEndIndex: number;

  isPagePrevBtnEnabled: boolean;
  isPageNextBtnEnabled: boolean;

  searchString: string;

  error: String;
  successMsg: String;
  deleteError: String;
  isLoading: Boolean;
  modalDisplay: any;
  downloadJsonHref: any;
  roasterId: number;
  roaster: Roaster;

  constructor(private service: RecipeService, private roasterService: RoasterService, private revisionService: RevisionService, private sanitizer: DomSanitizer, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.currentPageNumber = 0;
    this.pageSize = 8
    this.disableNext = false;

    this.pageStartIndex = this.currentPageNumber;
    this.pageEndIndex = this.currentPageNumber + this.pageSize;

    this.error = null;
    this.successMsg = null;

    this.allRecipes = new Array<RecipeWrapper>();

    this.route.queryParams
      .subscribe(params => {
        this.roasterId = params.roasterId;
      }
      );

    this.isLoading = true;

    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      this.roasterService.getRoaster(this.accessToken, this.roasterId).subscribe(
        data => {
          this.roaster = data;
        },
        error => {
          this.error = error.error.message;
          this.isLoading = false;
        }
      )

      this.service.getRoasterRecipes(this.accessToken, this.roasterId, this.currentPageNumber, this.pageSize).subscribe(
        data => {
          this.allRecipes = this.allRecipes.concat(<RecipeWrapper[]>data);
          this.recipes = <RecipeWrapper[]>data;
          this.isLoading = false;
        },
        error => {
          this.error = error.error.message;
          this.isLoading = false;
        }
      )

      this.revisionService.getRevisions(this.accessToken, null, null, 'COFFEE').subscribe(
        revisions => {
          this.revisions = revisions.map(r => ({
            ...r,
            displayName: `${r.subType}, ${r.revisionName}`
          }))
          this.isLoading = false;
        },
        error => {
          this.error = error.error.message;
          this.isLoading = false;
        }
      )
    })

  }

  nextPage() {

    this.currentPageNumber++;
    this.pageStartIndex = this.pageStartIndex + this.pageSize;

    this.recipes = this.allRecipes.slice(this.pageStartIndex, this.pageStartIndex + this.pageSize);

    this.error = null;
    this.successMsg = null;

    if (this.recipes.length === 0) {
      this.service.getRoasterRecipes(this.accessToken, this.roasterId, this.currentPageNumber, this.pageSize).subscribe(
        data => {
          this.allRecipes = this.allRecipes.concat(<RecipeWrapper[]>data);
          this.recipes = <RecipeWrapper[]>data;
          if (this.recipes.length == 0) {
            this.disableNext = true;
          }
        },
        error => {
          this.error = "Unable to connect to the service.";
        }
      )
    }
  }

  prevPage() {
    this.disableNext = false;

    this.currentPageNumber--;
    this.pageStartIndex = this.pageStartIndex - this.pageSize;

    this.recipes = this.allRecipes.slice(this.pageStartIndex, this.pageStartIndex + this.pageSize);

    this.error = null;
    this.successMsg = null;

  }

  submitRecipeOrder(recipeWrapper: RecipeWrapper) {
    this.error = null;
    this.successMsg = null;

    const { recipe: {id, recipeOrder} } = recipeWrapper;
    this.service.updateRoasterRecipeOrder(this.accessToken, id, recipeOrder).subscribe(
      data => {
        this.successMsg = "Updated";
      },
      err => {
        this.error = err.error.errors[0];
      }
    )
  }

  deleteRecipe(rw: RecipeWrapper) {
    this.service.deleteRoasterRecipe(this.accessToken, rw.recipe.id).subscribe(
      data => {
        var btnId = "delete-close-confirm-btn" + rw.recipe.id;
        let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
        element.click();
        rw.deleted = true;
      },
      error => {
        this.deleteError = error.error.message;
        this.ngOnInit();
      }
    )

  }

}
