import { Component, OnInit } from '@angular/core';
import { RecipeService } from '../service/recipe.service';
import { RevisionService } from '../../revision/service/revision.service';
import { Revision } from '../../revision/entities/revision';
import { Auth } from 'aws-amplify';
import { RecipeWrapper } from '../entities/RecipeWrapper';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-default-machine',
  templateUrl: './default-machine.component.html',
  styleUrls: ['./default-machine.component.css']
})
export class DefaultMachineComponent implements OnInit {

  accessToken: Object;
  recipes: RecipeWrapper[];
  error: String;
  successMsg: String;
  isLoading; Boolean;
  modalDisplay: any;
  downloadJsonHref: any;
  revisions: Revision[];
  revisionId: number;

  constructor(private service: RecipeService, private revisionService: RevisionService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.isLoading = true;

    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      this.revisionService.getRevisions(this.accessToken, null, null, 'COFFEE').subscribe(
        revisions => {
          this.revisions = revisions

          if (this.revisions.length) {
            this.revisionId = revisions[0].id
          }

          this.loadRecipes()
        },
        error => {
          this.error = error.error.message;
          this.isLoading = false;
        }
      )

    })

  }

  loadRecipes() {
    this.isLoading = true;

    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      this.service.getMachineDefaultRecipes(this.accessToken, this.revisionId).subscribe(
        data => {
          this.recipes = <RecipeWrapper[]>data;
          var theJSON = JSON.stringify(data);
          var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
          this.downloadJsonHref = uri;
          this.isLoading = false;
        },
        error => {
          this.error = error.error.message;
          this.isLoading = false;
        }
      )

    })
  }

  download() {
    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      this.service.getMachineDefaultRecipes(this.accessToken, this.revisionId).subscribe(
        data => {
          this.recipes = <RecipeWrapper[]>data;
          this.isLoading = false;
        },
        error => {
          this.error = error.error.message;
          this.isLoading = false;
        }
      )
    })

  }

  submitRecipeOrder(id: number, recipeOrder: number) {

    this.error = null;
    this.successMsg = null;

    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      this.service.setMachineDefaultRecipeOrder(this.accessToken, id, recipeOrder, this.revisionId).subscribe(
        data => {
          this.successMsg = "Updated";
        },
        err => {
          this.error = err.error.errors[0];
        }
      )

    })
  }

  changeSelectedRevision(revisionId: number) {
    this.revisionId = revisionId
    this.loadRecipes()
  }

}
