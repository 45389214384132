import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BrewCount } from '../home';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) { }

  getBrewStats(token) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<BrewCount>(this.apiUrl + '/admin/statistics/brew', { headers });
  }
}
