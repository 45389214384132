import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Warehouse } from '../warehouse';

@Injectable({
  providedIn: 'root'
})
export class WarehousesService {

  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) { }

  getWarehouses(token, from: Number, size: Number) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Warehouse[]>(this.apiUrl + '/admin/warehouse?from=' + from + '&pageSize=' + size, { headers });
  }

  getWarehouse(token, id: number) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Warehouse>(this.apiUrl + '/admin/warehouse/' + id, { headers });
  }

  createWarehouse(token, warehouse: Warehouse): Observable<Warehouse> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token); 

    return this.http.post<Warehouse>(this.apiUrl + '/admin/warehouse', warehouse, { headers });
  }

  updateWarehouse(token, warehouse: Warehouse): Observable<Warehouse> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put<Warehouse>(this.apiUrl + '/admin/warehouse/' + warehouse.id, warehouse, { headers });
  }

  updateWarehouseStatus(token, id: number, active: boolean): Observable<Warehouse> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    let body = {
      active: active
    };

    return this.http.patch<Warehouse>(this.apiUrl + '/admin/warehouse/' + id, body, { headers });
  }

  deleteWarehouse(token, warehouse: Warehouse) {
    const  headers = new  HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.delete( this.apiUrl + '/admin/warehouse/' + warehouse.id, {headers} );
  }

  getCountryList(token) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<{id: number, name: string}[]>(this.apiUrl + '/admin/country', { headers });
  }
}
