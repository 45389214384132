<div class="container my-5 content" *ngIf="authState !== 'signedin' && !user">

  <div class="row justify-content-md-center">
    <div class="col-md-12 align-middle my-5 text-center">
      <img src="../assets/logo.webp" class="img-fluid rounded mx-auto d-block my-2" alt="morning">
      <span>ADMIN PANEL</span><span class="badge" [style.background-color]="backColor">{{envname}}</span>
      <br>
      <div class="col-md-3 alert mt-2 mb-1 mx-auto alert-primary" role="alert">
        <small>Use the mobile app to sign up. Once done, inform the Administrator to gain access.</small>
      </div>

    </div>
    <div class="col-md-12">
      <amplify-authenticator *ngIf="authState !== 'signedin'" class="text-center">
        <amplify-sign-in slot="sign-in" hideSignUp></amplify-sign-in>
        <div>
          Admin Panel
          <amplify-sign-out></amplify-sign-out>
        </div>
      </amplify-authenticator>
    </div>
  </div>
</div>

<nav class="navbar navbar-dark sticky-top d-flex p-0 shadow" *ngIf="authState === 'signedin' && user">

  <a class="mr-auto navbar-brand col-md-3 col-lg-2 mr-0 px-3" [routerLink]="homeLink"><img src="../assets/favicon.ico"
      class="img-fluid rounded mx-1" alt="morning">MORNING <span class="badge"
      [style.background-color]="backColor">{{envname}}</span> </a>

  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse"
    data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <!-- <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search"> -->
  <span *ngIf="morningUser" class="text-white m-1">Hi {{morningUser.firstName}} </span>
  <img *ngIf="morningUser && morningUser.avatarUrl!=null" [src]="morningUser.avatarUrl" class="p-1" width="55" alt="avatar-image">

  <ul class="navbar-nav p-1">
    <li class="nav-item text-nowrap">
      <amplify-sign-out></amplify-sign-out>
    </li>
  </ul>

</nav>

<div class="container-fluid" *ngIf="authState === 'signedin' && user">
  <div class="row">
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div class="sidebar-sticky pt-3">

        <div *ngIf="groups.includes('ROLE_TECHNICIAN'); else otherRoles">
          <ul class="nav flex-column mb-2">
            <li class="nav-ite">
              <a class="nav-link" routerLink="/machine" routerLinkActive="active-menu-item">
                <i-feather name="columns"></i-feather>
                Machine
              </a>
            </li>
          </ul>
        </div>

        <ng-template #otherRoles>

          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="'/home'" routerLinkActive="active-menu-item">
                <span data-feather="home"></span>
                Home <span class="sr-only">(current)</span>
              </a>
            </li>
          </ul>

          <div *ngIf="groups.includes('ROLE_ADMIN') || groups.includes('ROLE_ROASTER')">

            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Roaster</span>
            </h6>

            <ul class="nav flex-column" *ngIf="groups.includes('ROLE_ADMIN')">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/roaster']" routerLinkActive="active-menu-item">
                  <i-feather name="user"></i-feather>
                  Roasters
                </a>
              </li>
            </ul>

            <ul class="nav flex-column">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/capsule']" [queryParams]="{ groups: groups }"
                  routerLinkActive="active-menu-item">
                  <i-feather name="circle"></i-feather>
                  Capsule
                </a>
              </li>
            </ul>

          </div>

          <div *ngIf="groups.includes('ROLE_ADMIN')">
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Admin</span>
            </h6>

            <ul class="nav flex-column mb-2">
              <li class="nav-item">
                <a class="nav-link" routerLink="/recipe" routerLinkActive="active-menu-item">
                  <i-feather name="coffee"></i-feather>
                  Default recipes
                </a>
              </li>
            </ul>
            <ul class="nav flex-column mb-2">
              <li class="nav-ite">
                <a class="nav-link" routerLink="/machine" routerLinkActive="active-menu-item">
                  <i-feather name="columns"></i-feather>
                  Machine
                </a>
              </li>
            </ul>
            <ul class="nav flex-column mb-2">
              <li class="nav-ite">
                <a class="nav-link" routerLink="/ota" routerLinkActive="active-menu-item">
                  <i-feather name="cpu"></i-feather>
                  OTA
                </a>
              </li>
            </ul>
            <ul class="nav flex-column mb-2">
              <li class="nav-ite">
                <a class="nav-link" routerLink="/warehouse" routerLinkActive="active-menu-item">
                  <i-feather name="home"></i-feather>
                  Warehouses
                </a>
              </li>
            </ul>
            <ul class="nav flex-column mb-2">
              <li class="nav-ite">
                <a class="nav-link" routerLink="/content" routerLinkActive="active-menu-item">
                  <i-feather name="file"></i-feather>
                  Content
                </a>
              </li>
            </ul>

          </div>
          <!-- *ngIf="groups.includes('ROLE_ADMIN')" -->
          <div *ngIf="groups.includes('ROLE_DEV')"> 
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Developer</span>
            </h6>

            <ul class="nav flex-column mb-2">
              <li class="nav-ite">
                <a class="nav-link" routerLink="/swagger" routerLinkActive="active-menu-item">
                  <i-feather name="code"></i-feather>
                  OpenAPI 3
                </a>
              </li>
            </ul>
            <ul class="nav flex-column mb-2">
              <li class="nav-ite">
                <a class="nav-link" routerLink="/metrics" routerLinkActive="active-menu-item">
                  <i-feather name="bar-chart"></i-feather>
                  API Metrics
                </a>
              </li>
            </ul>
            
          </div>

          <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Settings</span>          
          </h6>

          <ul class="nav flex-column mb-2">
            <li class="nav-ite">
              <a class="nav-link" routerLink="/profile" routerLinkActive="active-menu-item">
                <i-feather name="settings"></i-feather>
                Profile
              </a>
            </li>
          </ul>

        </ng-template>

        <ul class="nav flex-column mb-2">
          <li class="nav-ite">
            <div class="alert alert-warning m-2" role="alert">
              This application has only been tested with chrome <i-feather name="chrome"></i-feather>
            </div>
          </li>
        </ul>

      </div>
    </nav>

    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4 h-90">
      <router-outlet></router-outlet>
    </main>

  </div>
  

</div>
