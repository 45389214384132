<div class="d-flex pt-3 pb-2 mb-3 border-bottom">
    <h2 class="h2 mr-auto h1">User Default Coffee recipes</h2>


    <button type="button" class="btn btn-info btn-sm ml-1 mb-2" (click)="ngOnInit()">
        <i-feather name="refresh-ccw"></i-feather>
    </button>

</div>


<div class="row">

    <div *ngIf="error" class="col-md-6">
        <div class="alert alert-danger" role="alert">
            {{error}}
        </div>
    </div>

    <div class="col-md-12">

        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-end">
                <li class="page-item mx-2">
                    <select class="form-control" (change)="changePageSize($event.target.value)">
                        <option *ngFor="let i of pageSizes" [value]="i">{{i}}</option>
                    </select>
                </li>
                <li class="page-item" [ngClass]="(isPagePrevBtnEnabled==false)?'disabled':''">
                    <button class="page-link" (click)="getPrevPageSubSet()">Previous</button>
                </li>
                <li class="page-item" [ngClass]="(i==currentPageNumber)?'active-page':''"
                    *ngFor="let i of pageNumbers | slice:pageSubsetStartIndex:pageSubsetEndIndex">
                    <button class="page-link" (click)="getPageData(i,pageSize)">{{i}}</button>
                </li>
                <li class="page-item" [ngClass]="(isPageNextBtnEnabled==false)?'disabled':''">
                    <button class="page-link" (click)="getNextPageSubSet()">Next</button>
                </li>
            </ul>
        </nav>

    </div>

    <div class="col-md-12">
        <p *ngIf="isLoading"> Loading<img src="../../../assets/loading_dots.gif" height="50px"></p>
    </div>

    <div *ngIf="!error" class="col-md-12 table-responsive">

        <table class="table table-hover shadow">

            <thead class="table-head">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Water Output</th>
                    <th scope="col">Mass Output</th>
                    <th scope="col">Brew Time</th>
                    <th scope="col">Temperature</th>
                    <th scope="col">Bloom Duration</th>
                    <th scope="col" colspan="3">Pressure Profile</th>
                    <th scope="col">Author</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let rw of recipes; index as i; first as isFirst">

                    <td>{{i+1}}</td>
                    <td>{{rw.recipe.id}}</td>
                    <td>{{rw.recipe.name}} <span class="badge badge-success" *ngIf="rw.recipe.isNew">New</span></td>

                    <td *ngIf="rw.recipe.waterOutput">{{rw.recipe.waterOutput}}</td>
                    <td *ngIf="!rw.recipe.waterOutput"> - </td>

                    <td *ngIf="rw.recipe.massOutput">{{rw.recipe.massOutput}}</td>
                    <td *ngIf="!rw.recipe.massOutput"> - </td>

                    <td *ngIf="rw.recipe.brewTime">{{rw.recipe.brewTime}}</td>
                    <td *ngIf="!rw.recipe.brewTime"> - </td>

                    <td>{{rw.recipe.temperature}}</td>

                    <td *ngIf="rw.recipe.bloomDuration">{{rw.recipe.bloomDuration}}</td>
                    <td *ngIf="!rw.recipe.bloomDuration"> - </td>

                    <td *ngIf="rw.recipe.pressureProfile">
                        <ul class="list-group">
                            <li class="list-group-item">
                                <small>Stage 01</small>
                            </li>
                            <li class="list-group-item">Pressure:
                                {{rw.recipe.pressureProfile.stageDtos[0].pressure}}
                            </li>
                            <li class="list-group-item">
                                Output:
                                {{rw.recipe.pressureProfile.stageDtos[0].output}}
                            </li>
                        </ul>
                    </td>
                    <td *ngIf="rw.recipe.pressureProfile">
                        <ul class="list-group">
                            <li class="list-group-item">
                                <small>Stage 02</small>
                            </li>
                            <li class="list-group-item">
                                Pressure:
                                {{rw.recipe.pressureProfile.stageDtos[1].pressure}}
                            </li>
                            <li class="list-group-item">
                                Output:
                                {{rw.recipe.pressureProfile.stageDtos[1].output}}
                            </li>
                        </ul>
                    </td>
                    <td *ngIf="rw.recipe.pressureProfile">
                        <ul class="list-group">
                            <li class="list-group-item">
                                <small>Stage 03</small>
                            </li>
                            <li class="list-group-item">
                                Pressure:
                                {{rw.recipe.pressureProfile.stageDtos[2].pressure}}
                            </li>
                            <li class="list-group-item">
                                Output:
                                {{rw.recipe.pressureProfile.stageDtos[2].output}}
                            </li>
                        </ul>
                    </td>

                    <td *ngIf="!rw.recipe.pressureProfile"> - </td>
                    <td *ngIf="!rw.recipe.pressureProfile"> - </td>
                    <td *ngIf="!rw.recipe.pressureProfile"> - </td>

                    <td>{{rw.recipe.roaster.name}} ({{rw.recipe.authorName}}) </td>
 
                </tr>

            </tbody>
        </table>
    </div>
</div>