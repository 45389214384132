import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Recipe, ShopifyProduct } from '../entities/recipe';
import { RecipeService } from '../service/recipe.service';

@Component({
  selector: 'app-recipe-availabilities',
  templateUrl: './recipe-availabilities.component.html',
  styleUrls: ['./recipe-availabilities.component.css']
})
export class RecipeAvailabilitiesComponent implements OnInit {
  accessToken: Object;
  submitted: Boolean;
  error: String;
  recipeId: string;
  isLoading: Boolean;
  warehouseList: ShopifyProduct[]; 

  @Input() recipe: Recipe;
  @Output() recipeCreated = new EventEmitter();

  constructor(private service: RecipeService) { }

  ngOnInit(): void {
    this.recipeId = this.recipe.id.toString();
  }

  getWarehouseList() {
    this.isLoading = true;
    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      this.service.getRecipeAvailabilityList(this.accessToken, this.recipe.id).subscribe(
        data => {
          this.warehouseList = data;
          this.isLoading = false;
        },
        error => {
          this.error = error.error.message;
          this.isLoading = false;
        }
      )
    })
  }

  onSubmit(warehouseList) {
    this.submitted = true;
    this.error = null;

    Auth.currentSession().then(res => {

      var accessToken = res.getAccessToken().getJwtToken();

      this.service.updateRecipeAvailabilityList(accessToken, this.recipe.id, warehouseList).subscribe(
        data => {
          this.submitted = false;
          let btnId = "update-close-confirm-btn" + this.recipeId;
          let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
          element.click();
          this.loadRecipes();
        },
        error => {
          this.submitted = false;
          this.error = error.error.errors[0];
        }

      )

    })
  }

  loadRecipes() {
    this.recipeCreated.emit();
    this.ngOnInit();
  }

}
