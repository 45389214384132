export const environment = {
    envname: "",
    env: "PROD",
    backColor:"",
    amplifyConfig: {
        "aws_project_region": "ap-southeast-1",
        "Auth": {
            "identityPoolId": "ap-southeast-1:f5305f0e-09c7-4f5d-b390-153d2c998a5f",
            "region": "ap-southeast-1",
            "userPoolId": "ap-southeast-1_35sNfBuQq",
            "userPoolWebClientId": "1u5e8tpje6j6pk1m5mvlen86vd"
        },
        "API": {
            "endpoints": [{
                "name": "morning-api",
                "endpoint": "api.akronym.link",
                "endpointWithHttp": "https://api.akronym.link/v2"
            }]
        }
    },
    production: true,
    machineImportBucketName: 'morning-machine-for-import'
};