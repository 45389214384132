<div class="d-flex pt-3 pb-2 mb-3 border-bottom">

  <h2 class="h2 mr-auto">Roasters
    <i-feather name="arrow-right"></i-feather> Recipes of
    <span class="font-italic" *ngIf="this.roaster!=undefined && this.roaster.name!=undefined">"{{this.roaster.name}}"
    </span>
  </h2>

  <button type="button" class="btn btn-info m-1" (click)="ngOnInit()">
    <i-feather class="h-auto" name="refresh-ccw"></i-feather>
  </button>

  <button type="button" class="btn btn-primary m-1" routerLink="/roaster/roaster">
    <i-feather class="h-auto" name="arrow-left"></i-feather>
  </button>

  <app-create-recipe [recipeWrapper]=null [btnText]="'Add'" [roasterId]="this.roasterId"
    [roasterName]="this.roaster?.name" [revisions]="revisions" (recipeCreated)="ngOnInit()"></app-create-recipe>

</div>

<div class="row align-items-start pt-3 pb-2 mb-3">

  <div class="col-md-12">
    
    <div class="alert alert-light" role="alert">
      - The recipes are ordered alphabetically by name.<br>
      - If you do not see your changes immediately, please clear browser cache and refresh.
    </div>

    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-end">
        <ul>
          <div class="bg-danger text-white p-1 m-1 rounded" role="alert" *ngIf="error">
            {{error}}
          </div>
          <div class="bg-success text-white p-1 m-1 rounded" role="alert" *ngIf="successMsg">
            {{successMsg}}
          </div>
        </ul>
        <li class="page-item m-2">
          Page {{currentPageNumber+1}} ({{pageSize}} records per page)
        </li>
        <li class="page-item" [ngClass]="currentPageNumber===0?'disabled':''">
          <button class="page-link" (click)="prevPage()" [disabled]="currentPageNumber===0">Previous</button>
        </li>
        <li class="page-item" [ngClass]="disableNext===true?'disabled':''">
          <button class="page-link" (click)="nextPage()" [disabled]="disableNext===true">Next</button>
        </li>
      </ul>
    </nav>

  </div>

  <div class="col-md-12">
    <p *ngIf="isLoading"> Loading<img src="../../../assets/loading_dots.gif" height="50px"></p>
  </div>

  <div class="col-md-12 table-responsive">

    <table class="table table-hover shadow table-sm" style="font-size: 0.9rem">
      <thead class="table-head">
        <tr>
          <th scope="col">Recipe Details</th>

          <th scope="col">Pressure</th>
          <th scope="col">Author</th>
          <th scope="col" colspan="2">Order</th>
          <th scope="col" colspan="3">Actions</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let rw of recipes; index as i" [ngClass]="{'table-danger':this.rw.deleted}">
          <td>
            <table class="table table-bordered">
              <tr>
                <th class="table-secondary"><b>ID</b></th>
                <td>{{rw.recipe.id}}</td>
              </tr>
              <tr>
                <th class="table-secondary"><b>Name</b></th>
                <td>{{rw.recipe.name}} <span class="badge badge-success" *ngIf="rw.recipe.isNew">New</span></td>
              </tr>
              <tr *ngIf="rw.recipe.waterOutput">
                <th class="table-secondary"><b>Water output</b></th>
                <td>{{rw.recipe.waterOutput}}</td>
              </tr>
              <tr *ngIf="rw.recipe.massOutput">
                <th class="table-secondary"><b>Mass output</b></th>
                <td>{{rw.recipe.massOutput}}</td>
              </tr>
              <tr *ngIf="rw.recipe.brewTime">
                <th class="table-secondary"><b>Brew time</b></th>
                <td>{{rw.recipe.brewTime}}</td>
              </tr>
              <tr>
                <th class="table-secondary"><b>Temperature</b></th>
                <td>{{rw.recipe.temperature}}</td>
              </tr>
              <tr *ngIf="rw.recipe.bloomDuration">
                <th class="table-secondary"><b>Bloom Duration</b></th>
                <td>{{rw.recipe.bloomDuration}}</td>
              </tr>
              <tr *ngIf="rw.recipe.capsule">
                <th class="table-secondary"><b>Capsule</b></th>
                <td>{{rw.recipe.capsule.name}}</td>
              </tr>
            </table>
            <span *ngIf="rw.recipe.isDefault" class="badge badge-primary m-1">Default</span>
            <span *ngIf="rw.recipe.isMachineDefault" class="badge badge-info m-1">Machine Default</span>
          </td>

          <td style="width: 300px;">

            <div class="d-flex flex-row bd-highlight mb-3 align-items-end bg-white p-2 border" [style.height.px]="8*20">

              <div class="graph border shadow" style="width: 10px;"
                [style.height.px]="7*20">
                <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">7</div>
                <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">6</div>
                <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">5</div>
                <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">4</div>
                <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">3</div>
                <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">2</div>
                <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">1</div>
              </div>

              <div class="graph bg-primary border shadow"
                [style.width.%]="((rw.recipe.pressureProfile.stageDtos[1].output-rw.recipe.pressureProfile.stageDtos[0].output)/rw.recipe.massOutput)* 100"
                [style.height.px]="rw.recipe.pressureProfile.stageDtos[0].pressure*20">
                <span class="d-flex justify-content-center text-white">
                  {{rw.recipe.pressureProfile.stageDtos[1].output-rw.recipe.pressureProfile.stageDtos[0].output}}
                </span>
              </div>

              <div class="graph bg-success border shadow"
                [style.width.%]="((rw.recipe.pressureProfile.stageDtos[2].output-rw.recipe.pressureProfile.stageDtos[1].output)/rw.recipe.massOutput)* 100"
                [style.height.px]="rw.recipe.pressureProfile.stageDtos[1].pressure*20">
                <span class="d-flex justify-content-center">
                  {{rw.recipe.pressureProfile.stageDtos[2].output-rw.recipe.pressureProfile.stageDtos[1].output}}
                </span>
              </div>

              <div class="graph bg-danger border shadow"
                [style.width.%]="((rw.recipe.massOutput - rw.recipe.pressureProfile.stageDtos[2].output)/rw.recipe.massOutput)* 100"
                [style.height.px]="rw.recipe.pressureProfile.stageDtos[2].pressure*20">
                <span class="d-flex justify-content-center text-white">
                  {{rw.recipe.massOutput - rw.recipe.pressureProfile.stageDtos[2].output}}
                </span>
              </div>

            </div>
          </td>

          <td>{{rw.recipe.roaster.name}} ({{rw.recipe.authorName}})</td>
          
          <td class="bg-light border-left border-2">
            <input style="width: 55px" [attr.id]="'recipeOrder-'+rw.recipe.id" type="number" class="form-control form-control-sm" [(ngModel)]="rw.recipe.recipeOrder"
              min="0" oninput="validity.valid||(value='');">
          </td>
 
          <td class="bg-light border-right border-2">
            <button class="btn btn-sm"
              [disabled]="rw.recipe.recipeOrder == null"
              [class]="rw.recipe.recipeOrder == null ? 'btn-secondary' : 'btn-primary'"
              (click)="submitRecipeOrder(rw)">Save</button>
          </td>
          <td>
            <app-create-recipe [recipeWrapper]="rw" [btnText]="'Edit'" [roasterId]="this.roasterId"
              [roasterName]="this.roaster?.name" [revisions]="revisions" (recipeCreated)="ngOnInit()"></app-create-recipe>
          </td>
          <td>
            <app-recipe-availabilities [recipe]="rw.recipe" (recipeCreated)="ngOnInit()"></app-recipe-availabilities>
          </td>
          <td>
            <button type="button" class="btn btn-danger m-1" data-toggle="modal"
              [attr.data-target]="'#deleteModal'+rw.recipe.id">
              <i-feather class="h-auto" name="trash-2"></i-feather>
            </button>

            <div class="modal fade" [attr.id]="'deleteModal'+rw.recipe.id" tabindex="-1"
              aria-labelledby="deleteModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Recipe ( ID = {{rw.recipe.id}}) ?</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    Please click the Delete button to delete the recipes.
                    <div class="alert alert-danger mt-2" role="alert" *ngIf="deleteError">
                      {{deleteError}}
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button type="button" [attr.id]="'delete-close-confirm-btn'+rw.recipe.id" class="btn btn-secondary"
                      data-dismiss="modal">Cancel</button>
                    <button (click)="deleteRecipe(rw)" type="button" class="btn btn-danger">Delete</button>
                  </div>
                </div>
              </div>
            </div>
            
          </td>

        </tr>

      </tbody>
    </table>

  </div>
  <div class="col-md-12">

    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-end">
        <li class="page-item m-2">
          Page {{currentPageNumber+1}} ({{pageSize}} records per page)
        </li>
        <li class="page-item" [ngClass]="currentPageNumber===0?'disabled':''">
          <button class="page-link" (click)="prevPage()" [disabled]="currentPageNumber===0">Previous</button>
        </li>
        <li class="page-item" [ngClass]="disableNext===true?'disabled':''">
          <button class="page-link" (click)="nextPage()" [disabled]="disableNext===true">Next</button>
        </li>
      </ul>
    </nav>

  </div>

</div>