import { Roaster } from '../../roaster/Roaster';

export class Recipe {

  public id: number;
  public name: any;
  public temperature: any;
  public waterOutput: any;

  public isDefault: boolean;
  public defaultMachineRevisionIds: number[];
  public isNew: boolean;
  public authorName: string;
  public recipeOrder: number;

  public roaster: Roaster;
}

export class ShopifyProduct {
  warehouseId: number;
  warehouseName: string;
  shopifyProductId: string;
  forSale: boolean;
}