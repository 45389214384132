<button type="button" class="btn btn-warning m-1" data-toggle="modal"
    [attr.data-target]="'#recipe-roasterId'+this.roasterId+'-'+this.recipeWrapper.recipe.id">
    <i-feather class="h-auto" name="plus-circle" *ngIf="this.recipeWrapper.recipe.id==-1" title="Add Recipe"></i-feather>
    <i-feather class="h-auto" name="edit-2" *ngIf="this.recipeWrapper.recipe.id!=-1" title="Edit Recipe"></i-feather>
</button>


<div class="modal fade" [attr.id]="'recipe-roasterId'+this.roasterId+'-'+this.recipeWrapper.recipe.id"
    data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header card-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                    <span *ngIf="this.recipeWrapper.recipe.id!=-1">Update recipe of </span>
                    <span *ngIf="this.recipeWrapper.recipe.id==-1">Create recipe for </span>
                    <span class="font-italic">"{{roasterName}}"</span></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link btn-warning m-2" [ngClass]="isCoffee?'active':''" href="#"
                            (click)="showCoffee()">Coffee</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link btn-warning m-2 disabled" [ngClass]="isDripBag?'active':''" href="#"
                            (click)="showDripBag()">Drip bag</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link btn-warning m-2 disabled" [ngClass]="isTea?'active':''" href="#"
                            (click)="showTea()">Tea</a>
                    </li>
                </ul>

                <form *ngIf="isCoffee" class="m-2 border p-4 recipe-form"
                    [attr.name]="'recipeForm'+this.recipeWrapper.recipe.id" [formGroup]="recipeForm"
                    (ngSubmit)="onSubmit()" novalidate>
                    <h4>Create Coffee Recipe</h4>
                    <div class="form-group" *ngIf="this.recipeWrapper.recipe.id!=-1">
                        <label for="name">ID</label>
                        <input type="text" class="form-control" formControlName="id" disabled>
                    </div>

                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" formControlName="name">
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="temperature">Temperature <br> (between 75C and 98C)</label>
                            <input type="number" class="form-control" formControlName="temperature" required>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="water-output">Water output <br> (between 0g and 120g)</label>
                            <input type="number" class="form-control" formControlName="waterOutput" required>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="name">Bloom Duration in Seconds (Between 1s and 30s)</label>
                        <input type="number" class="form-control" formControlName="bloomDuration">
                    </div>

                    <div class="d-flex flex-row bd-highlight mb-3 align-items-end  p-2"
                        [ngClass]="this.stageDtos.controls[0].value.output+this.stageDtos.controls[1].value.output+this.stageDtos.controls[2].value.output !== this.recipeForm.value.massOutput || (this.stageDtos.controls[0].value.pressure > 7 || this.stageDtos.controls[1].value.pressure > 7 || this.stageDtos.controls[2].value.pressure > 7) || (this.stageDtos.controls[0].value.pressure < 1 || this.stageDtos.controls[1].value.pressure < 1 || this.stageDtos.controls[2].value.pressure < 1)?'bg-alert':'bg-white'">

                        <div class="graph border shadow" style="width: 10px;" [style.height.px]="7*20">
                            <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">7
                            </div>
                            <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">6
                            </div>
                            <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">5
                            </div>
                            <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">4
                            </div>
                            <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">3
                            </div>
                            <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">2
                            </div>
                            <div class="bg-secondary border-bottom text-white" style="width: 10px; height: 20px;">1
                            </div>
                        </div>

                        <div class="graph bg-primary border"
                            [style.width.%]="(this.stageDtos.controls[0].value.output/this.recipeForm.value.massOutput)* 100"
                            [style.height.px]="this.stageDtos.controls[0].value.pressure*20">
                            <span class="d-flex justify-content-center text-white">
                                {{this.stageDtos.controls[0].value.output}}
                            </span>

                        </div>
                        <div class="graph bg-success border"
                            [style.width.%]="(this.stageDtos.controls[1].value.output/this.recipeForm.value.massOutput)* 100"
                            [style.height.px]="this.stageDtos.controls[1].value.pressure*20">
                            <span class="d-flex justify-content-center text-white">
                                {{this.stageDtos.controls[1].value.output}}
                            </span>

                        </div>
                        <div class="graph bg-danger border"
                            [style.width.%]="(this.stageDtos.controls[2].value.output/this.recipeForm.value.massOutput)* 100"
                            [style.height.px]="this.stageDtos.controls[2].value.pressure*20">
                            <span class="d-flex justify-content-center text-white">
                                {{this.stageDtos.controls[2].value.output}}
                            </span>

                        </div>
                    </div>

                    <div class="alert alert-danger" role="alert"
                        *ngIf="this.stageDtos.controls[0].value.output+this.stageDtos.controls[1].value.output+this.stageDtos.controls[2].value.output !== this.recipeForm.value.massOutput">
                        Sum of the output of each stage should be equal to the Mass output. <i-feather
                            name="thumbs-down"></i-feather>
                    </div>

                    <div class="alert alert-primary" role="alert"
                        *ngIf="this.stageDtos.controls[0].value.output+this.stageDtos.controls[1].value.output+this.stageDtos.controls[2].value.output == this.recipeForm.value.massOutput">
                        Sum of the output of each stage is equal to the Mass output. <i-feather name="thumbs-up">
                        </i-feather>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="mass-output">Mass output <br> (between 25g and 150g)</label>
                            <span
                                *ngIf="this.stageDtos.controls[0].value.output+this.stageDtos.controls[1].value.output+this.stageDtos.controls[2].value.output !== this.recipeForm.value.massOutput"
                                class="badge badge-warning blink_me m-1">
                                <i-feather name="alert-triangle"></i-feather>
                            </span>
                            <input type="number" class="form-control" formControlName="massOutput" required>

                        </div>

                    </div>

                    <div formGroupName="pressureProfile" class="p-2 m-4">
                        <h5>Stages</h5>
                        <div formArrayName="stageDtos">
                            <div *ngFor="let stage of stageDtos.controls; index as i" [formGroupName]="i"
                                class="form-row bg-light m-2">

                                <div class="form-group col-md-4">
                                    <label for="stage1-pressure">Pressure (1-7)</label>
                                    <input type="number" class="form-control" formControlName="pressure">

                                </div>
                                <div class="form-group col-md-4">
                                    <label for="stage1-output">Output</label>
                                    <input type="number" class="form-control" formControlName="output"
                                        [disabled]="this.stageDtos.controls[0].value.output+this.stageDtos.controls[1].value.output+this.stageDtos.controls[2].value.output !== this.recipeForm.value.massOutput">
                                </div>

                            </div>
                        </div>
                    </div>

                    <div formGroupName="capsule" class="form-group p-2 mx-4 col-md-6">
                        <h5>Capsule Attachment</h5>

                        <input type="text" class="form-control mb-2" formControlName="id"
                            name="capsuleId" disabled placeholder="Capsule Id">

                        <app-capsule-search
                            [roasterId]="roasterId"
                            [selectedCapsule]="capsule"
                            (changeCapsuleId)="updateCapsule($event.capsuleId)">
                        </app-capsule-search>
                    </div>

                    <div class="accordion mb-4" [attr.id]="'accordion'+this.recipeWrapper.recipe.id+'-'+this.roasterId">
                        <div class="card default-settings-card">
                            <div class="card-header default-section-header"
                                [attr.id]="'heading'+this.recipeWrapper.recipe.id+'-'+this.roasterId">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left" type="button"
                                        data-toggle="collapse"
                                        [attr.data-target]="'#collapseOne'+this.recipeWrapper.recipe.id+'-'+this.roasterId"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        Click here to make default recipes
                                    </button>
                                </h2>
                            </div>

                            <div class="collapse"
                                [attr.aria-labelledby]="'#heading'+this.recipeWrapper.recipe.id+'-'+this.roasterId"
                                [attr.data-parent]="'#accordion'+this.recipeWrapper.recipe.id+'-'+this.roasterId"
                                [attr.id]="'collapseOne'+this.recipeWrapper.recipe.id+'-'+this.roasterId">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label for="is-Default">Default</label>
                                        <input class="ml-2 default-input" type="checkbox" formControlName="isDefault">
                                    </div>
                                    <div class="form-group">
                                        <label for="is-Machine-Default">Machine Default (select via revision)</label>
                                        <ng-select
                                            multiple="true"
                                            name="defaultMachineRevisionIds"
                                            [items]="revisions"
                                            bindValue="id"
                                            bindLabel="displayName"
                                            [hideSelected]="true"
                                            [closeOnSelect]="false"
                                            [clearSearchOnAdd]="true"
                                            notFoundText="Revision not found"
                                            formControlName="defaultMachineRevisionIds">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="submit" class="btn btn-secondary submit-btn"
                        [disabled]="!recipeForm.valid || submitted || (this.stageDtos.controls[0].value.output+this.stageDtos.controls[1].value.output+this.stageDtos.controls[2].value.output !== this.recipeForm.value.massOutput)">Submit</button>
                </form>

                <form *ngIf="isDripBag" class="m-2 border p-4 recipe-form"
                    [attr.name]="'dripBagRecipeForm'+this.recipeWrapper.recipe.id" [formGroup]="dripBagRecipeForm"
                    (ngSubmit)="onSubmit()" novalidate>
                    <h4>Create Drip Bag Recipe</h4>

                    <div class="form-group" *ngIf="this.recipeWrapper.recipe.id!=-1">
                        <label for="name">ID</label>
                        <input type="text" class="form-control" formControlName="id">
                    </div>

                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" formControlName="name">
                    </div>

                    <div class="form-group">
                        <label for="pre-infusion">Pre Infusion</label>
                        <input id="pre-infusion" class="ml-2" type="checkbox" formControlName="isPreInfusion">
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="mass-output">Brew Time</label>
                            <input type="number" class="form-control" formControlName="brewTime" required>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="temperature">Temperature</label>
                            <input type="number" class="form-control" formControlName="temperature" required>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="water-output">Water output</label>
                            <input type="number" class="form-control" formControlName="waterOutput" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="is-Default-dripbag">Default</label>
                        <input class="ml-2 default-input" type="checkbox" formControlName="isDefault">
                    </div>
                    <div class="form-group">
                        <label for="is-Machine-Default-dripbag">Machine Default</label>
                        <input class="ml-2 default-input" type="checkbox" formControlName="isMachineDefault">
                    </div>



                    <button type="submit" class="btn btn-secondary submit-btn"
                        [disabled]="!dripBagRecipeForm.valid || submitted">Submit</button>

                </form>

                <form *ngIf="isTea" class="m-2 border p-4 recipe-form"
                    [attr.name]="'teaRecipeForm'+this.recipeWrapper.recipe.id" [formGroup]="teaRecipeForm"
                    (ngSubmit)="onSubmit()" novalidate>
                    <h4>Create Tea Recipe</h4>
                    <div class="form-group" *ngIf="this.recipeWrapper.recipe.id!=-1">
                        <label for="name">ID</label>
                        <input type="text" class="form-control" formControlName="id">
                    </div>

                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" formControlName="name">
                    </div>

                    <div class="form-group">
                        <label for="rinse-cycle">Rinse Cycle</label>
                        <input id="rinse-cycle" class="ml-2" type="checkbox" formControlName="isRinseCycle">
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="mass-output">Brew Time</label>
                            <input type="number" class="form-control" formControlName="brewTime" required>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="temperature">Temperature</label>
                            <input type="number" class="form-control" formControlName="temperature" required>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="water-output">Mass output</label>
                            <input type="number" class="form-control" formControlName="massOutput" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="is-Default-tea">Default</label>
                        <input class="ml-2 default-input" type="checkbox" formControlName="isDefault">
                    </div>
                    <div class="form-group">
                        <label for="is-Machine-Default-tea">Machine Default</label>
                        <input class="ml-2 default-input" type="checkbox" formControlName="isMachineDefault">
                    </div>

                    <button type="submit" class="btn btn-secondary submit-btn"
                        [disabled]="!teaRecipeForm.valid || submitted">Submit</button>
                </form>

                <div class="alert alert-danger m-1" role="alert" *ngIf="error">
                    {{error}}
                </div>
            </div>
            <div class="modal-footer">
                <button type="button"
                    [attr.id]="'create-close-confirm-btn'+this.recipeWrapper.recipe.id+'-'+this.roasterId"
                    class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>