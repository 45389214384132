
<div class="row bg-light border-bottom" [ngClass]="isFullScreen?'stat':''">
  <div class="col-md-12 p-3 text-left">
    <img *ngIf="isFullScreen" src="../assets/logo.webp" class="img-fluid rounded ml-0 d-block my-2" alt="morning">
  </div>
  <div class="col-md-3 p-2">
    <div class="border-left border-warning p-2 shadow h-100">
      <h5 class="card-title d-flex justify-content-between">
        Machines
        <a routerLink="/machine" routerLinkActive="active-menu-item">
          <i-feather name="more-vertical"></i-feather>
        </a>
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">Number of machines</h6>
      <p class="card-text text-break display-4">
        <span class="badge mr-2" title="total count">{{machineCount}}</span>
        <span class="badge badge-success blink_me py-2" title="online count">{{machineOnlineCount}} </span>
      </p>
    </div>
  </div>
  <div class="col-md-3 p-2">
    <div class="border-left border-warning p-2 shadow h-100">
      <h5 class="card-title">Users</h5>
      <h6 class="card-subtitle mb-2 text-muted">Number of users</h6>
      <p class="card-text text-break display-4">{{userCount}}</p>
    </div>
  </div>
  <div class="col-md-3 p-2">
    <div class="border-left border-warning p-2 shadow h-100">
      <h5 class="card-title">Recipes</h5>
      <h6 class="card-subtitle mb-2 text-muted">Number of user recipes</h6>
      <p class="card-text text-break display-4">{{userRecipeCount}}</p>
    </div>
  </div>
  <div class="col-md-3 p-2">
    <div class="border-left border-warning p-2 shadow h-100">
      <h5 class="card-title">Brews on App</h5>
      <div class="custom-row">
        <div class="inner-wrapper">
          <h6 class="flex-50 card-subtitle text-muted">Last 24h</h6>
          <p class="flex-50 card-text text-break text-size">{{brewCount?.daily}}</p>
        </div>
        <div class="inner-wrapper">
          <h6 class="flex-50 card-subtitle text-muted">Last 7d</h6>
          <p class="flex-50 card-text text-break text-size">{{brewCount?.weekly}}</p>
        </div>
        <div class="inner-wrapper">
          <h6 class="flex-50 card-subtitle text-muted">Last 28d</h6>
          <p class="flex-50 card-text text-break text-size">{{brewCount?.monthly}}</p>
        </div>
      </div>
      
    </div>
  </div>

  <div class="col-lg-6 col-md-12 col-sm-12 p-2">
    <div class="border-left border-info p-2 shadow">
      <div class="card-title d-flex justify-content-between"><i-feather name="cpu"></i-feather> 
        <h5>Firmware Releases</h5>
        <a routerLink="/ota/ota-list" routerLinkActive="active-menu-item">
          <i-feather name="more-vertical"></i-feather>
        </a>
      </div>
      <h6 class="card-subtitle mb-2 text-muted">Last 8 releases
      </h6>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Version</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let o of otas">
            <td>
              {{o.fileName}} 
                <span *ngIf="o.isLatest" class="badge badge-info blink_me">RELEASED</span>
            </td>

            <td>{{o.createdDate | date:'medium':'MMM d, y, h:mm:ss a'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="col-lg-6 col-md-12 col-sm-12 p-2">
    <div class="border-left border-danger p-2 shadow">
      <div class="card-title d-flex justify-content-between">
        <h5 class="m-auto">
          <img src="../../../../assets/machine1.png" class="img-thumbnail" style="width: 70px;" alt="Morning machine"> 
          FW Release/Machine count
        </h5>
        <a routerLink="/ota/machine-ota-stat" routerLinkActive="active-menu-item">
          <i-feather name="more-vertical"></i-feather>
        </a>
      </div>
      <h6 class="card-subtitle mb-2 text-muted">Last 7 entries
      </h6>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Version</th>
            <th scope="col">Machine count</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let o of otaStat">
            <td>
              {{o.version}}
          </td>
          <td>{{o.count}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- <div class="col-lg-6 col-md-12 col-sm-12 p-2">
    <div class="col-md-6 p-2 m-auto">
      <img src="../../../../assets/machine1.png"
        class="img-fluid" alt="Responsive image">
    </div>
  
    <div class="col-md-12 p-2">
      <h2><b>Morning Machine</b></h2>
      <p class="text-justify">
        It’s the world’s first capsule machine that gives the home brewer full control on how you want 
        your coffee to taste. You can also create your own coffee recipes and share those recipes 
        (upcoming app update) by connecting your Morning Machine to our Morning Mobile App.  <a  target="_blank" href="https://www.drinkmorning.com/pages/the-morning-machine" role="button">Read more . . .</a>
      </p>
      
    </div>
  </div> -->

  <div class="col-md-12 p-2 text-right">
    <span class="mr-2" *ngIf="isLoading1 || isLoading2 || isLoading3 || isLoading4"> Loading<img
        src="../../../assets/loading_dots.gif" height="30px"></span>
    <span class="mr-1 text-muted">This section refreshes after every 1 minute</span>

    <button type="button" class="btn btn-info mr-1" (click)="ngOnInit()">Refresh</button>

    <button type="button" class="btn btn-info mr-1" (click)="isFullScreen = !isFullScreen">
      <i-feather *ngIf="!isFullScreen" name="maximize"></i-feather>
      <i-feather *ngIf="isFullScreen" name="minimize"></i-feather>
    </button>

  </div>
</div>

<!-- <div class="row bg-light border-bottom">
    <div class="col-md-4 p-2">
        <div class="border-left border-info p-2 shadow">
            <h5 class="card-title">Firmware Status</h5>
            <h6 class="card-subtitle mb-2 text-muted">Releases</h6>
            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Version</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>0.2.5</td>
                    <td>22</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>1.0.3</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>1.0.3</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>1.0.3</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>1.0.3</td>
                    <td>4</td>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>
    <div class="col-md-4 p-2">
        <div class="border-left border-info p-2 shadow">
            <h5 class="card-title"></h5>
            <h6 class="card-subtitle mb-2 text-muted">More stats will be added later. . . </h6>
        </div>
    </div>
</div> -->


<!-- <div class="d-flex justify-content-center my-5">

  <div class="col-md-4">
    <img src="../../../../assets/editorial_generic_co.thumbnail.overview-image.730.411_1100x800_crop_center.jpg"
      class="img-fluid" alt="Responsive image">
  </div>

  <div class="col-md-5">
    <h2><b>ANDRE & LEON</b></h2>
    <h3>Founders of Morning</h3>
    <p class="text-justify">
      Andre & Leon started Morning with a mission to bring better tasting coffee to more people.
      Armed with at least 10 years in the Specialty Coffee industry, both founders are building
      the Morning ecosystem one step at a time. In 2019, the capsule marketplace was soft launched
      in preparation for the launch of Morning's capsule brewing machine, due in 2020.
    </p>
    <a class="btn btn-primary" target="_blank" href="https://www.drinkmorning.com/" role="button">More</a>
  </div>
</div> -->