import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Roaster } from '../Roaster';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoasterService {

  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) { }

  getRoasters(token, from: Number, size: Number) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Roaster[]>(this.apiUrl + '/admin/roaster?from='+from+'&pageSize='+size+'&fields=name', { headers });
    
  }

  getRoaster(token, id: number) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Roaster>(this.apiUrl + '/roaster/'+id, { headers });
    
  }

  addRoaster(token, roaster: Roaster): Observable<Roaster> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.post<Roaster>(this.apiUrl + '/admin/roaster', roaster, { headers });
  }

  updateFeatured(token, id: number, featured: Boolean, featuredOrder: Number){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);
    const body = {
      featured: featured,
      featuredOrder: featuredOrder
    }

    return this.http.patch(`${this.apiUrl}/admin/roaster/${id}`, body, {headers});

  }

  updateRoasterOrder(token, id: number, roasterOrder: Number){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);
    const body = {
      roasterOrder: roasterOrder,
    }

    return this.http.patch(`${this.apiUrl}/admin/roaster/${id}`, body, {headers});

  }

  getFeaturedRoasters(token) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Roaster[]>(this.apiUrl + '/roaster/featured', { headers });

  }

  updateRoaster(token, roaster: Roaster) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put(this.apiUrl + '/admin/roaster/'+roaster.id, roaster, { headers });
  }

  hideRoaster(token, id: number, hidden: boolean){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);
    
    const body = {
      hidden: hidden,
    }

    return this.http.patch(`${this.apiUrl}/admin/roaster/${id}`, body, {headers});
  }

  makeRoasterPartner(token, id: number, isPartner: boolean){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);
    
    const body = {
      isPartner: isPartner,
    }

    return this.http.patch(`${this.apiUrl}/admin/roaster/${id}`, body, {headers});
  }

  changeStatus(token, id: number, status: string){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);
    
    const body = {
      status: status,
    }

    return this.http.patch(`${this.apiUrl}/admin/roaster/${id}`, body, {headers});
  }

  search(token, search: string, from: number, size: number){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Roaster[]>(this.apiUrl + '/admin/roaster/search?search='+encodeURI(search)+'&from='+from+'&pageSize='+size+'&fields=name', { headers });
  }
}
