<div>
    <input type="text" class="form-control" [ngModel]="capsuleName" (ngModelChange)="searchCapsule($event)"
        placeholder="Capsule..."  (focus)="triggerSearchCapsule()">
    <small>{{capsuleNameWarning}}</small>
    <div *ngIf="showCapsules">
        <table class="table table-bordered table-hover shadow" id="capsules">
            <thead class="thead-capsules">
                <tr>
                    <th scope="col">
                        <div class="m-2">ID</div>
                    </th>
                    <th scope="col">
                        <nav aria-label="Page navigation">
                            <ul class="pagination justify-content-end m-0">
                                <li class="page-item m-2 flex-grow-1">
                                    Name
                                </li>
                                <li class="page-item" [ngClass]="currentPageNumber===0?'disabled':''">
                                    <button class="page-link" type="button" (click)="searchCapsulePrevPage()"
                                        [disabled]="currentPageNumber===0">&laquo;</button>
                                </li>
                                <li class="page-item" [ngClass]="disableNext===true?'disabled':''">
                                    <button class="page-link" type="button" (click)="searchCapsuleNextPage()"
                                        [disabled]="disableNext===true">&raquo;</button>
                                </li>
                            </ul>
                        </nav>

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let capsule of capsules" (click)="capsuleId=capsule.id; capsuleName=capsule.name; this.showCapsules = false; updateCapsuleId();">
                    <td scope="row">{{capsule.id}}</td>
                    <td>{{capsule.name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>