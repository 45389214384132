import { NgModule } from '@angular/core';
import { Routes, RouterModule,  } from '@angular/router';

const routes: Routes = [
  {path : 'capsule', loadChildren : () => import('./capsule/capsule.module').then(m => m.CapsuleModule)},
  {path : 'home', loadChildren : './home/home.module#HomeModule'},
  {path : 'machine', loadChildren : () => import('./machine/machine.module').then(m => m.MachineModule)},
  {path : 'metrics', loadChildren : () => import('./api-metrics/api-metrics.module').then(m => m.ApiMetricsModule)},
  {path : 'ota', loadChildren : () => import('./ota/ota.module').then(m => m.OtaModule)},
  {path : 'profile', loadChildren : './profile/profile.module#ProfileModule'},
  {path : 'recipe', loadChildren : () => import('./recipe/recipe.module').then(m => m.RecipeModule)},
  {path : 'roaster', loadChildren : () => import('./roaster/roaster.module').then(m => m.RoasterModule)},
  {path : 'swagger', loadChildren : './swagger/swagger.module#SwaggerModule'},
  {path : 'warehouse', loadChildren : () => import('./warehouse/warehouse.module').then(m => m.WarehouseModule)},
  {path : 'content', loadChildren : () => import('./content/content.module').then(m => m.ContentModule)},
  {path : 'revision', loadChildren : () => import('./revision/revision.module').then(m => m.RevisionModule)},
  {path: '',   redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }