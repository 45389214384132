<!-- Button trigger modal -->
<button type="button" class="btn btn-warning m-1" data-toggle="modal" [attr.data-target]="'#recipeId'+recipeId" (click)="getWarehouseList()">
  <i-feather class="h-auto" name="shopping-cart" title="Buy"></i-feather>
</button>

<!-- Modal -->
<div class="modal fade" [attr.id]="'recipeId'+recipeId" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true" [ngClass]="{'hidden': isLoading}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header card-header">
        <h5 class="modal-title" id="staticBackdropLabel">Recipe Availabilities</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="loadRecipes()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="container-fluid" *ngIf="warehouseList?.length; else noData">

          <form #recipeAvailabilityForm="ngForm" class="row" (ngSubmit)="onSubmit(warehouseList)" novalidate>
            <div class="col-md-12">

              <div class="form-group mb-4" *ngFor="let warehouse of warehouseList">
                <h6 class="font-weight-bold">{{ warehouse.warehouseName }}</h6>
                <label>Shopify Product ID</label>
                <input type="text" class="form-control" [(ngModel)]="warehouse.shopifyProductId" [name]="warehouse.warehouseName">
                <div class="form-group form-check mt-2">
                  <input type="checkbox" class="form-check-input align-middle" [id]="warehouse.warehouseId"
                    [(ngModel)]="warehouse.forSale" [checked]="warehouse.forSale" [name]="warehouse.warehouseId">
                  <label class="form-check-label align-middle" [for]="warehouse.warehouseId">For Sale?</label>
                </div>
              </div>

            </div>

            <button type="submit" class="btn btn-warning submit-btn"
              [disabled]="submitted || recipeAvailabilityForm.form.invalid">Submit</button>

          </form>

          <div class="alert alert-danger mt-2" role="alert" *ngIf="error">
            {{error}}
          </div>

        </div>
        <ng-template #noData>
          <div class="no-data">No data</div>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button"
        [attr.id]="'update-close-confirm-btn'+recipeId" 
        class="btn btn-secondary" (click)="loadRecipes()" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>