import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Capsule } from 'src/app/capsule/capsule';
import { RecipeService } from '../service/recipe.service';

@Component({
  selector: 'app-capsule-search',
  templateUrl: './capsule-search.component.html',
  styleUrls: ['./capsule-search.component.css']
})
export class CapsuleSearchComponent implements OnInit {

  capsules : Array<Capsule>;
  allCapsules: Array<Capsule>;
  showCapsules: boolean;
  accessToken: Object;
  error: String;
  successMsg: String;

  currentPageNumber: number;
  disableNext: boolean;
  pageStartIndex: number;
  pageEndIndex: number;
  pageSize: number;
  searchString: any;
  capsuleName: String;
  capsuleId: number;
  capsuleNameWarning: string;

  @Input() roasterId: number;
  @Input() selectedCapsule: {
    id: number,
    name: string,
    producer: string
  };
  @Output() changeCapsuleId = new EventEmitter();

  constructor(private recipeService: RecipeService) { }

  ngOnInit(): void {
    this.showCapsules = false;

    if(this.selectedCapsule?.name) {
      this.capsuleId = this.selectedCapsule.id;
      this.capsuleName = this.selectedCapsule.name;
      this.updateCapsuleId();
    }
  }

  updateCapsuleId() {
    const capsuleJson = {
      "capsuleId" : this.capsuleId,
      "capsuleName" : this.capsuleName
    };
    this.changeCapsuleId.emit(capsuleJson);
  }

  triggerSearchCapsule(){
    this.searchCapsule(this.searchString);
  }

  searchCapsule(event: any){
    this.searchString = event;

    if(event) {
      this.currentPageNumber = 0;
      this.pageSize = 5;
      this.disableNext = false;
      this.pageStartIndex = this.currentPageNumber;
      this.pageEndIndex = this.currentPageNumber + this.pageSize;
  
      Auth.currentSession().then(res => {
  
        this.accessToken = res.getAccessToken().getJwtToken();
  
        this.recipeService.searchCapsule(this.accessToken, this.roasterId, this.searchString, this.pageSize, this.currentPageNumber).subscribe(
          data => {
            this.capsules = <Capsule[]>data;
            this.allCapsules = <Capsule[]>data;
            if (this.capsules.length < this.pageSize) {
              this.disableNext = true;
            }
            if(this.capsules.length!==0){
              this.showCapsules = true;
              this.capsuleNameWarning = null;
            } else {
              this.showCapsules = false;
              this.capsuleNameWarning = "(invalid capsule)";
            }
          },
          error => {
            this.error = error.error.message;
          }
        ) 
      })
    } else {
      if(this.searchString===""){
        this.capsules = null;
        this.allCapsules = null;
        this.showCapsules = false;
  
        this.capsuleId = null;
        this.capsuleName = null;
  
        this.capsuleNameWarning = null;
        this.updateCapsuleId();
        return;
      }
    }
  }

  searchCapsuleNextPage() {

    this.currentPageNumber++;
    this.pageStartIndex = this.pageStartIndex + this.pageSize;
    this.capsules = this.allCapsules.slice(this.pageStartIndex, this.pageStartIndex + this.pageSize);

    if (this.capsules.length === 0) {
      Auth.currentSession().then(res => {

        this.accessToken = res.getAccessToken().getJwtToken();
  
        this.recipeService.searchCapsule(this.accessToken, this.roasterId, this.searchString, this.pageSize, this.currentPageNumber).subscribe(
          data => {
            this.allCapsules = this.allCapsules.concat(<Capsule[]>data);
            this.capsules = <Capsule[]>data;
            if (this.capsules.length < this.pageSize) {
              this.disableNext = true;
            }
          },
          error => {
            this.error = "Unable to connect to the service.";
          }
        )
      })
    }
    
  }

  searchCapsulePrevPage() {
    this.disableNext = false;

    this.currentPageNumber--;
    this.pageStartIndex = this.pageStartIndex - this.pageSize;

    this.capsules = this.allCapsules.slice(this.pageStartIndex, this.pageStartIndex + this.pageSize);

    this.error = null;
    this.successMsg = null;

  }

}
