import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Recipe } from '../entities/recipe';
import { CoffeeRecipe, PressureProfile } from '../entities/coffee-recipe';
import { DripBagRecipe } from '../entities/drip-bag-recipe';
import { TeaRecipe } from '../entities/tea-recipe';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { RecipeService } from '../service/recipe.service';
import { Auth } from 'aws-amplify';
import { RecipeWrapper } from '../entities/RecipeWrapper';
import { Roaster } from 'src/app/roaster/Roaster';
import { Revision } from '../../revision/entities/revision';

@Component({
  selector: 'app-create-recipe',
  templateUrl: './create-recipe.component.html',
  styleUrls: ['./create-recipe.component.css']
})
export class CreateRecipeComponent implements OnInit {

  @Input() recipeWrapper: RecipeWrapper;
  @Input() btnText: String;
  @Input() btnIcon: String;
  @Input() roasterId: number;
  @Input() roasterName: string;
  @Input() revisions: Revision[];

  @Output() recipeCreated = new EventEmitter();

  submitted = false;
  error: string;
  accessToken: Object;

  isCoffee: boolean;
  isDripBag: boolean;
  isTea: boolean;
  capsule: {
    id: number,
    name: string,
    producer: string
  };

  constructor(private fb: FormBuilder, private service: RecipeService) { }

  recipeForm: FormGroup;
  dripBagRecipeForm: FormGroup;
  teaRecipeForm: FormGroup;

  ngOnInit(): void {
    this.isCoffee = true;

    if (this.recipeWrapper == null || this.recipeWrapper.recipe.id == -1) {

      this.recipeWrapper = new RecipeWrapper();
      this.recipeWrapper.recipe = new Recipe();
      this.recipeWrapper.recipe.id = -1;

      var stages = this.fb.array([
        this.fb.group({
          pressure: this.fb.control({ value: 1, disabled: false }, [Validators.required, Validators.min(1), Validators.max(7)]),
          output: this.fb.control({ value: 5, disabled: false }, [Validators.required, Validators.min(2)])
        }),
        this.fb.group({
          pressure: this.fb.control({ value: 7, disabled: false }, [Validators.required, Validators.min(1), Validators.max(7)]),
          output: this.fb.control({ value: 30, disabled: false }, [Validators.required, Validators.min(5)])
        }),
        this.fb.group({
          pressure: this.fb.control({ value: 1, disabled: false }, [Validators.required, Validators.min(1), Validators.max(7)]),
          output: this.fb.control({ value: 5, disabled: false }, [Validators.required, Validators.min(2)])
        }),
      ])

      this.recipeForm = this.fb.group({
        id: this.fb.control({ value: -1, disabled: false }),
        name: this.fb.control({ value: '', disabled: false }, Validators.required),
        massOutput: this.fb.control({ value: 40, disabled: false }, [Validators.required, Validators.min(20), Validators.max(150)]),
        temperature: this.fb.control({ value: 94, disabled: false }, [Validators.required, Validators.min(75), Validators.max(98)]),
        waterOutput: this.fb.control({ value: 0, disabled: false }, [Validators.min(0), Validators.max(120)]),
        bloomDuration: this.fb.control({ value: '', disabled: false }, [Validators.min(1), Validators.max(30)]),
        pressureProfile: this.fb.group({
          stageDtos: stages
        }),
        isDefault: this.fb.control({ value: false, disabled: false }, Validators.required),
        defaultMachineRevisionIds: this.fb.control({ value: [], disabled: false }),
        capsule: this.fb.group({
          id: null
        })
      })

      this.dripBagRecipeForm = this.fb.group({
        id: this.fb.control({ value: -1, disabled: false }),
        name: this.fb.control({ value: '', disabled: false }, Validators.required),
        temperature: this.fb.control({ value: '', disabled: false }, [Validators.required, Validators.min(75), Validators.max(98)]),
        waterOutput: this.fb.control({ value: 0, disabled: false }, [Validators.required, Validators.min(80), Validators.max(300)]),
        brewTime: this.fb.control({ value: '', disabled: false }, Validators.required),
        isPreInfusion: this.fb.control({ value: false, disabled: false }, Validators.required),
        isDefault: this.fb.control({ value: false, disabled: false }, Validators.required),
        defaultMachineRevisionIds: this.fb.control({ value: [], disabled: false })
      })

      this.teaRecipeForm = this.fb.group({
        id: this.fb.control({ value: -1, disabled: false }),
        name: this.fb.control({ value: '', disabled: false }, Validators.required),
        temperature: this.fb.control({ value: '', disabled: false }, Validators.required),
        massOutput: this.fb.control({ value: '', disabled: false }, Validators.required),
        brewTime: this.fb.control({ value: '', disabled: false }, Validators.required),
        isRinseCycle: this.fb.control({ value: false, disabled: false }, Validators.required),
        isDefault: this.fb.control({ value: false, disabled: false }, Validators.required),
        defaultMachineRevisionIds: this.fb.control({ value: [], disabled: false })
      })

    } else {

      if (this.recipeWrapper.type === "COFFEE_RECIPE") {

        this.isCoffee = true;
        this.isDripBag = false;
        this.isTea = false;

        var coffeeRecipe = this.recipeWrapper.recipe as CoffeeRecipe;
        this.capsule = coffeeRecipe?.capsule;

        var stages = this.fb.array([
          this.fb.group({
            pressure: this.fb.control({ value: coffeeRecipe.pressureProfile.stageDtos[0].pressure, disabled: false }, 
              [Validators.required, Validators.min(1), Validators.max(7)]),
            output: this.fb.control({ value: coffeeRecipe.pressureProfile.stageDtos[1].output - coffeeRecipe.pressureProfile.stageDtos[0].output, disabled: false }, 
              [Validators.required, Validators.min(2)])
          }),
          this.fb.group({
            pressure: this.fb.control({ value: coffeeRecipe.pressureProfile.stageDtos[1].pressure, disabled: false }, 
              [Validators.required, Validators.min(1), Validators.max(7)]),
            output: this.fb.control({ value: coffeeRecipe.pressureProfile.stageDtos[2].output - coffeeRecipe.pressureProfile.stageDtos[1].output, disabled: false }, 
              [Validators.required, Validators.min(5)])
          }),
          this.fb.group({
            pressure: this.fb.control({ value: coffeeRecipe.pressureProfile.stageDtos[2].pressure, disabled: false }, 
              [Validators.required, Validators.min(1), Validators.max(7)]),
            output: this.fb.control({ value: coffeeRecipe.massOutput - coffeeRecipe.pressureProfile.stageDtos[2].output, disabled: false }, 
              [Validators.required, Validators.min(2)])
          })
        ])

        this.recipeForm = this.fb.group({
          id: this.fb.control({ value: coffeeRecipe.id, disabled: false }),
          name: this.fb.control({ value: coffeeRecipe.name, disabled: false }, Validators.required),
          massOutput: this.fb.control({ value: coffeeRecipe.massOutput, disabled: false }, [Validators.required, Validators.min(20), Validators.max(150)]),
          temperature: this.fb.control({ value: coffeeRecipe.temperature, disabled: false }, [Validators.required, Validators.min(75), Validators.max(98)]),
          waterOutput: this.fb.control({ value: coffeeRecipe.waterOutput, disabled: false }, [Validators.min(0), Validators.max(120)]),
          bloomDuration: this.fb.control({ value: coffeeRecipe.bloomDuration, disabled: false }, [Validators.min(1), Validators.max(30)]),
          pressureProfile: this.fb.group({
            stageDtos: stages
          }),
          isDefault: this.fb.control({ value: coffeeRecipe.isDefault, disabled: false }, Validators.required),
          defaultMachineRevisionIds: this.fb.control({ value: coffeeRecipe.defaultMachineRevisionIds, disabled: false }),
          capsule: this.fb.group({
            id: null
          })
        })

      } else if (this.recipeWrapper.type === "DRIP_BAG_RECIPE") {

        this.isCoffee = false;
        this.isDripBag = true;
        this.isTea = false;

        var dripBagRecipe = this.recipeWrapper.recipe as DripBagRecipe;

        this.dripBagRecipeForm = this.fb.group({
          id: this.fb.control({ value: dripBagRecipe.id, disabled: false }),
          name: this.fb.control({ value: dripBagRecipe.name, disabled: false }, Validators.required),
          temperature: this.fb.control({ value: dripBagRecipe.temperature, disabled: false }, Validators.required),
          waterOutput: this.fb.control({ value: dripBagRecipe.waterOutput, disabled: false }, Validators.required),
          brewTime: this.fb.control({ value: dripBagRecipe.brewTime, disabled: false }, Validators.required),
          isPreInfusion: this.fb.control({ value: dripBagRecipe.isPreInfusion, disabled: false }, Validators.required),
          isDefault: this.fb.control({ value: dripBagRecipe.isDefault, disabled: false }, Validators.required),
          defaultMachineRevisionIds: this.fb.control({ value: dripBagRecipe.defaultMachineRevisionIds, disabled: false })
        })

      } else if (this.recipeWrapper.type === "TEA_RECIPE") {

        this.isCoffee = false;
        this.isDripBag = false;
        this.isTea = true;

        var teaRecipe = this.recipeWrapper.recipe as TeaRecipe;

        this.teaRecipeForm = this.fb.group({
          id: this.fb.control({ value: teaRecipe.id, disabled: false }),
          name: this.fb.control({ value: teaRecipe.name, disabled: false }, Validators.required),
          temperature: this.fb.control({ value: teaRecipe.temperature, disabled: false }, Validators.required),
          massOutput: this.fb.control({ value: teaRecipe.massOutput, disabled: false }, Validators.required),
          brewTime: this.fb.control({ value: teaRecipe.brewTime, disabled: false }, Validators.required),
          isRinseCycle: this.fb.control({ value: teaRecipe.isRinseCycle, disabled: false }, Validators.required),
          isDefault: this.fb.control({ value: teaRecipe.isDefault, disabled: false }, Validators.required),
          defaultMachineRevisionIds: this.fb.control({ value: teaRecipe.defaultMachineRevisionIds, disabled: false })
        })
      }

    }

  }

  showCoffee() {
    if (this.recipeWrapper.recipe.id === -1) {
      this.isCoffee = true;
      this.isDripBag = false;
      this.isTea = false;
    }
  }

  showDripBag() {
    if (this.recipeWrapper.recipe.id === -1) {
      this.isCoffee = false;
      this.isDripBag = true;
      this.isTea = false;
    }
  }

  showTea() {
    if (this.recipeWrapper.recipe.id === -1) {
      this.isCoffee = false;
      this.isDripBag = false;
      this.isTea = true;
    }
  }

  get stageDtos() {
    return this.recipeForm.get('pressureProfile.stageDtos') as FormArray;
  }

  onSubmit() {

    if (this.recipeForm.value.id != -1 || this.dripBagRecipeForm.value.id != -1 || this.teaRecipeForm.value.id != -1) {
      this.updateRecipe();
      return;
    }

    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      if (this.isCoffee) {

        var coffeeRecipe = <CoffeeRecipe>this.recipeForm.value;
        coffeeRecipe.roaster = new Roaster();
        coffeeRecipe.roaster.id = this.roasterId;

        var stageDtos = new Array<any>();

        var stage1 = {
          "pressure" : coffeeRecipe.pressureProfile.stageDtos[0].pressure,
          "output" : 0,
        }

        var stage2 = {
          "pressure" : coffeeRecipe.pressureProfile.stageDtos[1].pressure,
          "output" : coffeeRecipe.pressureProfile.stageDtos[0].output,
        }

        var stage3 = {
          "pressure" : coffeeRecipe.pressureProfile.stageDtos[2].pressure,
          "output" : coffeeRecipe.massOutput - coffeeRecipe.pressureProfile.stageDtos[2].output,
        }

        stageDtos.push(stage1);
        stageDtos.push(stage2);
        stageDtos.push(stage3);

        var pressureProfile =  new PressureProfile(stageDtos);

        coffeeRecipe.pressureProfile = pressureProfile;

        this.service.addCoffeeRecipe(this.accessToken, coffeeRecipe).subscribe(
          data => {
            this.submitted = false;
            var btnId = "create-close-confirm-btn" + -1 + "-" + this.roasterId;
            let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
            element.click();
            this.recipeCreated.emit();
            this.ngOnInit();
          },
          error => {
            this.error = error.error.message;
            this.submitted = false;
          }
        )

      } else if (this.isDripBag) {

        var recipe = this.dripBagRecipeForm.value;
        recipe.roaster = new Roaster();
        recipe.roaster.id = this.roasterId;

        this.service.addDripBagRecipe(this.accessToken, recipe).subscribe(
          data => {
            this.submitted = false;
            var btnId = "create-close-confirm-btn" + -1 + "-" + this.roasterId;
            let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
            element.click();
            this.recipeCreated.emit();
          },
          error => {
            this.error = error.error.message;
            this.submitted = false;
          }
        )

      } else if (this.isTea) {

        var recipe = this.teaRecipeForm.value;
        recipe.roaster = new Roaster();
        recipe.roaster.id = this.roasterId;

        this.service.addTeaRecipe(this.accessToken, recipe).subscribe(
          data => {
            this.submitted = false;
            var btnId = "create-close-confirm-btn" + -1 + "-" + this.roasterId;
            let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
            element.click();
            this.recipeCreated.emit();
          },
          error => {
            this.error = error.error.message;
            this.submitted = false;
          }
        )

      }
    })
  }

  updateRecipe() {

    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      if (this.isCoffee) {

        var recipe = this.recipeForm.value;
        recipe.roaster = new Roaster();
        recipe.roaster.id = this.roasterId;

        var stageDtos = new Array<any>();

        var stage1 = {
          "pressure" : recipe.pressureProfile.stageDtos[0].pressure,
          "output" : 0,
        }

        var stage2 = {
          "pressure" : recipe.pressureProfile.stageDtos[1].pressure,
          "output" : recipe.pressureProfile.stageDtos[0].output,
        }

        var stage3 = {
          "pressure" : recipe.pressureProfile.stageDtos[2].pressure,
          "output" : recipe.massOutput - recipe.pressureProfile.stageDtos[2].output,
        }

        stageDtos.push(stage1);
        stageDtos.push(stage2);
        stageDtos.push(stage3);

        var pressureProfile =  new PressureProfile(stageDtos);

        recipe.pressureProfile = pressureProfile;

        this.service.updateRoasterCoffeeRecipe(this.accessToken, recipe).subscribe(
          data => {
            this.submitted = false;
            var btnId = "create-close-confirm-btn" + recipe.id + "-" + this.roasterId;
            let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
            element.click();
            this.recipeCreated.emit();
          },
          error => {
            this.error = error.error.message;
            this.submitted = false;
          }
        )

      } else if (this.isDripBag) {

        var recipe = this.dripBagRecipeForm.value;
        recipe.roaster = new Roaster();
        recipe.roaster.id = this.roasterId;

        this.service.updateDripBagRecipe(this.accessToken, recipe).subscribe(
          data => {
            this.submitted = false;
            var btnId = "create-close-confirm-btn" + recipe.id + "-" + this.roasterId;
            let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
            element.click();
            this.recipeCreated.emit();
          },
          error => {
            this.error = error.error.message;
            this.submitted = false;
          }
        )

      } else if (this.isTea) {

        var recipe = this.teaRecipeForm.value;
        recipe.roaster = new Roaster();
        recipe.roaster.id = this.roasterId;

        this.service.updateTeaRecipe(this.accessToken, recipe).subscribe(
          data => {
            this.submitted = false;
            var btnId = "create-close-confirm-btn" + recipe.id + "-" + this.roasterId;
            let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
            element.click();
            this.recipeCreated.emit();
          },
          error => {
            this.error = error.error.message;
            this.submitted = false;
          }
        )

      }
    })
  }

  updateCapsule(event: Event) {
    this.recipeForm.get('capsule')?.setValue({id: event});
  }

}
