import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Revision } from '../entities/revision';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RevisionService {

  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) {}

  getRevisions(token, from=null, pageSize=null, type=null, subType=null) {

    const  headers = new  HttpHeaders().set("authorization", "Bearer "+token);
    let url = this.apiUrl+'/admin/revisions'
    let queryChunks = []

    if (from) {
      queryChunks.push(`from=${from}`)
    }
    if (pageSize) {
      queryChunks.push(`pageSize=${pageSize}`)
    }
    if (type) {
      queryChunks.push(`type=${type}`)
    }
    if (subType) {
      queryChunks.push(`subType=${subType}`)
    }

    return this.http.get<Revision[]>(url + (queryChunks.length ? ('?' + queryChunks.join('&')) : ''), {headers} );
    
  }

  createRevision(token, revision: Partial<Revision>) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.post<Revision>(this.apiUrl + '/admin/revisions/', revision, { headers });
  }

  updateRevision(token, id: number, revision: Partial<Revision>) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put<Revision>(this.apiUrl + '/admin/revisions/' + id, revision, { headers });
  }

  cloneRevision(token, id: number) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.post<Revision>(this.apiUrl + `/admin/revisions/${id}/clone`, {}, { headers });
  }

}
